import { Dialog } from "@mui/material";
import { Button, ButtonOutline, Text } from "components";
import styled from "styled-components/macro";

interface Props {
  title: string;
  subTitle?: string;
  confirmButtonText?: string;
  isOpen: boolean;
  loading?: boolean;
  onClose: () => void;
  callback: () => void;
}

const ConfirmModal = ({
  isOpen,
  onClose,
  callback,
  loading,
  title,
  subTitle,
  confirmButtonText,
}: Props) => {
  return (
    <BaseDialog open={isOpen} onClose={onClose} maxWidth="lg">
      <Wrapper>
        <HeaderBox>
          <Text size="xl" className="title">
            {title}
          </Text>

          {subTitle && (
            <Text size="md" className="sub-title">
              {subTitle}
            </Text>
          )}
        </HeaderBox>

        <GroupButton>
          <ButtonOutline onClick={onClose}>Cancel</ButtonOutline>

          <Button onClick={callback} loading={loading}>
            {confirmButtonText || "Confirm"}
          </Button>
        </GroupButton>
      </Wrapper>
    </BaseDialog>
  );
};

export default ConfirmModal;

const BaseDialog = styled(Dialog)`
  padding: 30px;
  .MuiPaper-root {
    border-radius: 16px;
    margin: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  max-width: 520px;
  max-height: calc(100vh - 44px);
  overflow-y: auto;
  gap: 32px;
`;

const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  text-align: center;

  .title {
    font-weight: 700;
  }

  .sub-title {
    font-size: 16px;
    font-weight: 400;
    padding: 0px 24px;
  }
`;

const GroupButton = styled.div`
  display: flex;
  gap: 16px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column-reverse;
  }

  .button-wrapper,
  button {
    width: 100%;
  }

  .button-wrapper {
    button {
      white-space: nowrap;
    }
  }
`;
