import { Popover, PopoverProps } from "@mui/material";
import { getAuth } from "firebase/auth";
import { useState } from "react";

import { LogoutIcon, SettingsIcon } from "assets/images/common";
import { Text } from "components";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { AUTH_MODE_TYPE } from "models/auth.model";
import { selectAuthMode } from "store/auth/auth.selector";
import { authActions } from "store/auth/auth.slice";
import styled from "styled-components/macro";
import { WorkspaceSettingsModal } from "./WorkspaceSettingsModal";

const hideSettings = [AUTH_MODE_TYPE.USER, AUTH_MODE_TYPE.ADMIN];

const ProfilePanel = (props: PopoverProps) => {
  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();
  const authMode = useAppSelector(selectAuthMode);

  const handleLogout = async () => {
    getAuth()
      .signOut()
      .then(() => {
        dispatch(authActions.userLogout());
      });
  };
  return (
    <Popover
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      PaperProps={{
        sx: {
          width: "250px",
          borderRadius: "16px",
          boxShadow: "-5px 26px 35px rgba(0, 0, 0, 0.08)",
        },
      }}
      {...props}
    >
      <PopoverWrapper>
        <Title>Profile</Title>

        {!hideSettings.includes(authMode) && (
          <ActionItem onClick={() => setOpen(true)}>
            <SettingsIcon />
            <Text size="md" fontSize="16px">
              Settings
            </Text>
          </ActionItem>
        )}

        <ActionItem onClick={handleLogout}>
          <LogoutIcon />
          <Text size="md" fontSize="16px">
            Log Out
          </Text>
        </ActionItem>
      </PopoverWrapper>

      <WorkspaceSettingsModal isOpen={open} onClose={() => setOpen(false)} />
    </Popover>
  );
};

export default ProfilePanel;

const PopoverWrapper = styled.div`
  padding: 20px;
  background-color: white;
`;

const ActionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  margin: 0px -8px;
  border-radius: 4px;
  color: ${(props) => props.theme.palette.secondary.main};
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #ebecf0;
  }
`;

const Title = styled.div`
  color: ${(props) => props.theme.palette.secondary.dark};
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
`;
